import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { addDays, format } from "date-fns";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export function getInitials(fullName: string): string {
  const [firstName, ...restNames] = fullName.toUpperCase().trim().split(" ");

  if (!restNames.length) {
    return firstName?.substring(0, 2) ?? "";
  }

  const firstNameInitial = firstName?.[0] ?? "";
  const lastNameInitial = restNames.pop()?.[0];

  return `${firstNameInitial}${lastNameInitial ?? ""}`;
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function formatDate(date: Date) {
  return format(date, "LLL dd, y");
}

export function formatDateTime(date: Date) {
  return format(date, "LLL dd, y HH:mm:ss");
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function uniqueFilter<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index;
}

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const getDomainFromEmail = (email: string) => {
  if (email.indexOf("@") <= 0) return email;
  return email.split("@")[1];
};

export async function copyToClipboard(value: string) {
  await navigator.clipboard.writeText(value);
}

export function numberFormatter(value: number) {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(value);
}

/**
 * Whenever you select a date, it will use the midnight timestamp of that date.
 * We need to add a day minus one second to include the whole day.
 */
export function manipulateDate(
  date?: {
    from: Date | undefined;
    to?: Date | undefined;
  } | null,
) {
  const isToDateMidnight = String(date?.to?.getTime()).endsWith("00000");

  // We might wanna use `endOfDay(new Date(date.to))` here
  const addOneDayToDate = date?.to
    ? addDays(new Date(date.to), 1).getTime() - 1
    : null;

  return {
    fromDate: date?.from?.getTime() ?? null,
    toDate: isToDateMidnight ? addOneDayToDate : date?.to?.getTime() ?? null,
  };
}

export function toCapitalize(inputString: string) {
  const words = inputString.split(/[\s_]+/); // Split the input string by spaces or underscores

  // Capitalize the first letter of each word
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
}
