"use client";

import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

import { Button } from "@flow/ui/button";

export default function CalButton() {
  useEffect(() => {
    void (async () => {
      const cal = await getCalApi({});
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <Button
      type="button"
      data-cal-namespace=""
      data-cal-config='{"layout":"month_view"}'
      data-cal-link="flowanalytics/15min"
      variant="outline"
      className="m-0"
    >
      Request a demo
    </Button>
  );
}
