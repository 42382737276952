"use client";

import Image from "next/image";
import * as React from "react";

import { Card, CardContent } from "@flow/ui/card";
import type { CarouselApi } from "@flow/ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@flow/ui/carousel";
import { H3 } from "@flow/ui/typography";

import { cn } from "~/lib/utils";

const images = ["/one.png", "/two.png", "/three.png"];

export function ImageCarousel() {
  const [api, setApi] = React.useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  const [_count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div className="mt-36w-full">
      <div className="container mb-12 mt-36 w-full px-4 md:px-6">
        <section className="max-w-lg pr-4">
          <H3 className="text-4xl font-normal">
            Prioritize Projects Based on Impact
          </H3>
          <p className=" pt-1 text-base font-normal text-gray-600">
            Connect your metrics, and make data-driven decisions about
            prioritization and resourcing. Gain clarity on which projects
            actually drive impact.
          </p>
        </section>
      </div>
      <Carousel
        setApi={setApi}
        className="mx-auto w-full"
        opts={{
          loop: true,
        }}
      >
        <CarouselContent className="-ml-20">
          {images.map((src, index) => (
            <CarouselItem key={src} className="max-w-4xl pl-20">
              <Card className="border-0 p-0 ring-0">
                <CardContent className="bg-background-faded rounded-xl flex aspect-video items-center justify-center p-0">
                  <Image
                    src={src}
                    alt={"title"}
                    width={1280}
                    height={720}
                    className={cn(
                      "transition-opacity",
                      current === index + 1 ? "opacity-100" : "opacity-20",
                    )}
                  />
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="absolute left-0 " />
        <CarouselNext className="absolute right-0 " />
      </Carousel>
      {/* <div className="py-2 text-center text-sm text-muted-foreground">
        Slide {current} of {count}
      </div> */}
    </div>
  );
}
